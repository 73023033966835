//TitleImage Component
import * as React from 'react';
//import { useEffect, useState } from 'react';
import './titleimage.scss';

//import './toc.scss';
//import TImage from '../../markdown/blog/cloud.jpg';

export interface TitleImageProps {
    title: string;
  src: string;
  bRandom: boolean;
  bRequire: boolean;
}
const unsplashPath = 'https://source.unsplash.com/random';

const TitleImage = (props: TitleImageProps) => {
  const { title, src, bRandom, bRequire } = props;
  const basePath = "/";
  const titleImage = bRandom? unsplashPath : basePath+src;

  /*
  const [ titleImage, setTitleImage] = useState("");

  useEffect(() => {
    const imagePath_1 = bRandom ? unsplashPath : src;
    const imagePath_2 = bRequire ? require(imagePath_1) : imagePath_1
    setTitleImage(imagePath_2);
    console.log("component did mount or update")
    return () => {
      console.log("component will unmount or update")
    }
  }, []);
*/

  return (<div id="inner_header_container">
      {/*<img id="inner_header_image" src={basePath+titleImage} /> */}
      <div id="inner_header_image" className='parallax' style={{backgroundImage:'url('+titleImage+")"}} />
      <h1 id="inner_header_title" >{title}</h1>
      
  </div>);
};

export default TitleImage;
